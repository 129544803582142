export const createDOMPurifyConfig = () => {
  return {
    ADD_TAGS: [
      'h3',
      'strong',
      'u',
      'img',
      'span',
      'a',
      'pre',
      'ul',
      'li',
      'iframe',
      'table',
      'tr',
      'td',
      'th',
      'thead',
      'tbody',
      'tfoot',
      'col',
      'colgroup',
      'caption',
    ] as string[],
    ADD_ATTR: [
      'style',
      'href',
      'target',
      'rel',
      'class',
      'spellcheck',
      'src',
      'width',
      'height',
      'allowfullscreen',
      'frameborder',
      'allow',
      'colspan',
      'rowspan',
      'scope',
    ] as string[],
  };
};
