import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ContactForm,
  LoginRequest,
  RegisterRequest,
  SocialMediaHandle,
} from 'models/user';
import UserService from 'services/userService';
import { clearUser } from './userReducer';
import axios from 'axios';
import ContactService from 'services/contactService';

export const register = createAsyncThunk(
  'user/register',
  async (registerRequest: RegisterRequest, { rejectWithValue }) => {
    try {
      const data = await UserService.registerService(registerRequest);
      return data;
    } catch (error) {
      const message = (error as Error).message || 'Failed to register';
      return rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  'user/login',
  async (credentials: LoginRequest, { rejectWithValue }) => {
    try {
      const data = await UserService.loginService(credentials);
      return data;
    } catch (error) {
      const message = (error as Error).message || 'Failed to log in';
      return rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await UserService.logoutService();
      dispatch(clearUser());
    } catch (error) {
      const message = (error as Error).message || 'Failed to log out';
      return rejectWithValue(message);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (userId: number, { rejectWithValue }) => {
    try {
      const userProfile = await UserService.getUserProfileService(userId);
      return userProfile;
    } catch (error) {
      const message =
        (error as Error).message || 'Failed to fetch user profile';
      return rejectWithValue(message);
    }
  }
);

export const uploadProfilePicture = createAsyncThunk(
  'user/uploadProfilePicture',
  async (
    { userId, file }: { userId: number; file: File },
    { rejectWithValue }
  ) => {
    try {
      const { presignedUrl, imageUrl } =
        await UserService.getPresignedUrlForProfilePicture(
          file.name,
          file.type
        );

      await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      await UserService.updateUserProfile(userId, { profilePicture: imageUrl });

      return imageUrl;
    } catch (error: any) {
      console.error('Failed to upload profile picture:', error);
      return rejectWithValue(
        error.response?.data || 'Unable to upload profile picture'
      );
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'user/updateUserDetails',
  async (
    {
      userId,
      bio,
      socialMediaHandles,
    }: { userId: number; bio: string; socialMediaHandles: SocialMediaHandle[] },
    { rejectWithValue }
  ) => {
    try {
      const profileData = { bio, socialMediaHandles };
      const updatedUserDetails = await UserService.updateUserDetails(
        userId,
        profileData
      );
      return updatedUserDetails;
    } catch (error: any) {
      console.error('Failed to update user details:', error);
      const errorMessage =
        error.response?.data?.message || 'Unable to update user details';
      return rejectWithValue(errorMessage);
    }
  }
);

export const sendContactForm = createAsyncThunk(
  'contact/sendContactForm',
  async (formData: ContactForm, { rejectWithValue }) => {
    try {
      const response = await ContactService.sendContactForm(formData);
      return response;
    } catch (error: any) {
      const message =
        error.response?.data?.message || 'Failed to send contact form';
      return rejectWithValue(message);
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  'user/sendPasswordResetEmail',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await UserService.sendPasswordResetEmail(email);
      return response;
    } catch (error) {
      const message =
        (error as Error).message || 'Failed to send password reset email';
      return rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (
    { token, newPassword }: { token: string; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await UserService.resetPasswordService(
        token,
        newPassword
      );
      return response;
    } catch (error: any) {
      const message =
        error.response?.data?.message || 'Failed to reset password';
      return rejectWithValue(message);
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  'users/fetchAll',
  async (
    { page, size }: { page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const users = await UserService.fetchAllUsers(page, size);
      return users;
    } catch (error: any) {
      const message = error.response?.data?.message || 'Failed to fetch users';
      return rejectWithValue(message);
    }
  }
);

export const updateUserRoles = createAsyncThunk(
  'user/updateUserRoles',
  async (
    { userId, roles }: { userId: number; roles: string[] },
    { rejectWithValue }
  ) => {
    try {
      const updatedUser = await UserService.updateUserRoles(userId, roles);
      return updatedUser;
    } catch (error: any) {
      const message =
        error.response?.data?.message || 'Failed to update user roles';
      return rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (userId: number, { rejectWithValue }) => {
    try {
      await UserService.deleteUser(userId);
      return userId;
    } catch (error: any) {
      const message = error.response?.data?.message || 'Failed to delete user';
      return rejectWithValue(message);
    }
  }
);

export const getUserPosts = createAsyncThunk(
  'user/getUserPosts',
  async (
    { userId, page, size }: { userId: number; page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const posts = await UserService.getUserPosts(userId, page, size);
      return posts;
    } catch (error: any) {
      const message =
        error.response?.data?.message || 'Failed to fetch user posts';
      return rejectWithValue(message);
    }
  }
);
